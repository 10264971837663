<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="create"
          >新建</el-button
        >
      </div>
      <div class="sg-search-box">
        <el-row>
          <el-col :span="6"
            ><el-input
              v-model="searchItem.title"
              placeholder="请输入关键词搜索"
              @blur="search"
            ></el-input
          ></el-col>
        </el-row>
      </div>
      <el-table :data="list" style="width: 100%" stripe>
        <el-table-column
          prop="title"
          label="标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="desc"
          label="描述"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="category_name" label="分类" align="center">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.category.name }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="cover" label="封面" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.cover" class="sg-img-sm" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <StatusTag :tags="statuses" :status="scope.row.status"></StatusTag>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.updated_at | formatDate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" size="mini">编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteRow(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="sg-pagination">
        <el-pagination
          @current-change="pageChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :title="action == 'create' ? '新建' : '编辑'"
      :visible.sync="showDialog"
      :fullscreen="true"
    >
      <div class="sg-dialog-body" v-if="showDialog">
        <el-row>
          <el-col :sm="{ span: 18, offset: 3 }">
            <el-form
              ref="createEditForm"
              :model="item"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item prop="title" label="标题">
                <el-input
                  v-model="item.title"
                  placeholder="请输入标题"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="desc" label="描述">
                <el-input
                  v-model="item.desc"
                  placeholder="请输入描述"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="sort" label="排序">
                <el-input
                  v-model.number="item.sort"
                  placeholder="请输入排序，数字越大越靠前"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item prop="category_id" label="分类">
                <el-select v-model="item.category_id" placeholder="请选择分类">
                  <el-option
                    v-for="item in categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item prop="cover" label="封面">
                <!-- <UploadCard v-model="item.cover"></UploadCard> -->
                <sg-upload v-model="item.cover"></sg-upload>
              </el-form-item>
              <el-form-item prop="content" label="内容">
                <!-- <HtmlEditor
                  v-model="item.content"
                  v-if="showDialog"
                ></HtmlEditor> -->
                <MyVueEditor v-model="item.content"
                  v-if="showDialog"></MyVueEditor>
              </el-form-item>
              <el-form-item prop="status" label="状态">
                <el-radio-group v-model="item.status">
                  <el-radio
                    :label="item.key"
                    v-for="(item, index) in statuses"
                    :key="index"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex"
// import HtmlEditor from "@/components/Share/HtmlEditor.vue";
import MyVueEditor from "@/components/Share/MyVueEditor.vue";
import SgUpload from '../../components/Share/sg-upload.vue';

export default {
  name: "Article",
  components: {
    // HtmlEditor,
    MyVueEditor,
    SgUpload,
  },
  data() {
    return {
      title: "新闻管理",
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      showDialog: false,
      pre_uri: "articles/",
      categories: [],
      // create/update
      action: "create",
      statuses: [
        {
          type: "success",
          label: "发布",
          key: 1,
        },
        {
          type: "info",
          label: "草稿",
          key: 2,
        },
        // {
        //   type: "danger",
        //   label: "回收站",
        //   key: 3,
        // },
      ],
      emptyItem: {
        category_id: "",
        title: "",
        desc: '',
        cover: "",
        status: "",
        sort: ''
      },
      item: {},
      searchItem: {
        title: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        sort: [
          {required: true, message: '请输入排序', trigger: "blur"},
          {type: 'number', message: '排序必须为数字值', trigger: "blur"},
        ]
      },
    };
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.admin.isLogin,
    }),
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    if(!this.isLogin) return false
    this.getCategories();
    this.getList();
  },
  methods: {
    search() {
      this.current = 1;
      this.getList();
    },
    getCategories() {
      this.$util.getData(this, "categories/getAll", {}, "categories");
    },
    getList() {
      var that = this;
      this.$store.commit("updateLoading", true);
      this.$http
        .post(
          this.pre_uri + "get",
          Object.assign(
            {
              page: this.current,
            },
            this.searchItem
          )
        )
        .then((res) => {
          this.$store.commit("updateLoading", false);
          if (res.code == 0) {
            that.$util.parsePagiList(that, res.data);
          }
        });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    create() {
      this.item = Object.assign({}, this.emptyItem);
      this.action = "create";
      this.switchShowDialog(true);
    },
    edit(item) {
      this.item = Object.assign({}, item);
      this.action = "edit";
      this.switchShowDialog(true);
    },
    deleteRow(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(this.pre_uri + "delete", {
              id: row.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    switchShowDialog(show) {
      this.showDialog = show;
      
    },
    submit() {
      this.$refs["createEditForm"].validate((valid) => {
        if (valid) {
          this.submitForm();
          return true;
        } else {
          return false;
        }
      });
    },
    submitForm() {
      var url = this.pre_uri + (this.action == "create" ? "store" : "update");
      this.$http.post(url, this.item).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.switchShowDialog(false);
          this.getList();
        }
      });
    },
  },
};
</script>
