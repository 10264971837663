<template>
  <div 
      id="sg-editor">
    <vue-editor
      v-model="content"
      :useCustomImageHandler="true"
      @image-added="handleImageAdded"
      :editorOptions="{
        scrollingContainer: '#sg-editor'
      }"
    />
  </div>
</template>
  
<script>
import { VueEditor } from "vue2-editor";
import { API_FILE_UPLOAD_URL } from "../../services/env.js";

export default {
  name: "MyVueEditor",
  props: {
    value: {
      type: String,
      value: "",
    },
  },
  data: () => {
    return {};
  },
  components: { VueEditor },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    console.log("file");
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      console.log(file);
      var formData = new FormData();
      formData.append("file", file);

      this.$http
        .request({
          url: API_FILE_UPLOAD_URL,
          method: "POST",
          data: formData,
        })
        .then((result) => {
          if (result.code == 0) {
            const url = result.data.path; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
@import "@/assets/scss/global.scss";
</style>
<style lang="scss">
.ql-editor {
  max-height: 300px;
  overflow-y: scroll;
}
</style>